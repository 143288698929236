<template>
  <div class="finance">
    <left-tabs :tabList="leftList" />
    <router-view></router-view>
  </div>
</template>

<script>
import LeftTabs from "@/components/leftTabs";
export default {
  components: {
    LeftTabs,
  },
  name: "shopfinanceList",
  data() {
    return {
      leftList: [
        { id: 1, name: "门店提现审核", url: "/finance/shop/list" },
        { id: 2, name: "用户审核", url: "/finance/user/list" },
        { id: 3, name: "收支明细", url: "/finance/income" },
        { id: 3, name: "店铺保证金", url: "/finance/storeDeposit" },
      ],
    };
  },
  methods: {},
};
</script>

<style scoped lang='less'>
.finance {
  display: flex;
}
</style>